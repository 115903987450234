import { useState, useEffect } from 'react';
import AnalysisTerminal from '../components/AnalysisTerminal';
import AppNavigation from '../components/AppNavigation';
import Layout from '../components/Layout';
import { fetchImageMetaData, fetchMeshMetaData } from '../utils/fetchData';


function AnalyzeView({ navData }) {



    return <Layout
        mainComponent={AnalysisTerminal}
        mainData={'define later'}
        navComponent={AppNavigation}
        navData={{ navData: navData }}
    />

}


export default AnalyzeView;