import React from 'react';
import {HStack, Text, Spacer} from '@chakra-ui/react'

import logo from '../logo.png';

function AppHeader() {
  return (
    <HStack>
      <img src={logo} alt="Logo" style={{ width: '50px', height: '50px', marginBottom: '5px' }} />
      <Spacer w="10px"/>
      <Text style={{ 
        color: "black",
        fontSize: '56px',
        fontFamily: 'Bebas Neue',
        textAlign: 'left', 
        paddingLeft: '0px',
        lineHeight: '100px',
        marginBottom: "0px" }}>
        qualify-ai
      </Text>
    </HStack>
  );
}

export default AppHeader;
