import React from 'react';
import { useState } from 'react';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Button,
    useDisclosure,
    Text,
    IconButton,
    Box,
    HStack,
    Spacer
} from '@chakra-ui/react'
import { ImageIcon, MeshIcon, UploadIcon } from '../components/Icons'
import { FolderPicker } from '../components/FolderPicker'
import { uploadImageMetadata, uploadToS3 } from '../utils/uploadData';
import UploadProgress from '../components/UploadProgress';

export function UploadFolder() {

    const { isOpen, onOpen, onClose } = useDisclosure()
    const isCentered = true;
    const [acceptedFiles, setAcceptedFiles] = React.useState([]);
    const [folderName, setFolderName] = React.useState(null);
    const [uploadProgress, setUploadProgress] = useState(0);


    const handleUpload = () => {
        uploadImageMetadata({
            folderName,
            itemNames: acceptedFiles.map(file => file.name)
        }).then((presignedURLs) => {
            console.log("presignedURLs before upload function", presignedURLs)

            uploadToS3(acceptedFiles, presignedURLs, setUploadProgress);
        })
        // await uploadToS3(acceptedFiles, presignedURLs);
    };

    return (
        <>
            <IconButton
                aria-label='Upload Folder'
                icon={<UploadIcon />}
                fontSize="20px"
                border="none"
                bg="white"
                marginLeft="0px"
                rounded="50px"
                _hover={{ bg: "gray.100" }}
                onClick={() => {
                    onOpen()
                }}
            />
            <Modal isOpen={isOpen} onClose={onClose} isCentered={isCentered}>
                <ModalOverlay backdropFilter='blur(10px) hue-rotate(90deg)' />
                <ModalContent>
                    <ModalHeader style={{ fontSize: "20px", fontFamily: 'Space Mono' }} >
                        Upload Folder
                    </ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <FolderPicker
                            onFilesChange={setAcceptedFiles}
                            onFolderNameChange={setFolderName}
                        />
                    </ModalBody>

                    <ModalFooter>
                        <UploadProgress
                            uploadProgress={uploadProgress}
                            onUploadClick={handleUpload}
                            onModalClose={onClose}
                        />
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    )
}

export default UploadFolder