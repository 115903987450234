import { Icon } from '@chakra-ui/react'
import { RxImage, RxCube, RxUpload } from 'react-icons/rx'

export const ImageIcon = () => {
  return <Icon as={ RxImage} />
}

export const MeshIcon = () => {
  return <Icon as={ RxCube} />
} 

export const UploadIcon = () => {
  return <Icon 
    as={ RxUpload} 
    border = "none"/>
}

