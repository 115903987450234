async function fetchImageMetaData() {
    const response = await fetch(
        `${process.env.REACT_APP_API_URL}/private/data/image-set/all/`,
        {
            method: 'GET',
            credentials: 'include',
        }
    );
    const responseBody = await response.json();
    console.log("imageResponse", responseBody);

    // return responseBody;

    const transformedData = responseBody.results.map(item => ({
        foldername: item.folderName,
        type: "image",
        creation: item.creation,
        data: item.itemNames.map(name => ({
            filename: `${name}`,
            type: "image"
        })),
        s3path: item.s3path
    }));

    return transformedData;
}

async function fetchMeshMetaData() {
    const response = await fetch(
        `${process.env.REACT_APP_API_URL}/private/data/mesh/all/`,
        {
            method: 'GET',
            credentials: 'include',
        }
    );
    const responseBody = await response.json();
    console.log("meshResponse", responseBody);

    // return responseBody;

    const transformedData = responseBody.results.map(item => ({
        foldername: item.s3path.split('/')[1],
        type: "mesh",
        creation: item.creation,
        data: item.itemNames.map(name => ({
            filename: `${name}`,
            type: "mesh"
        })),
        s3path: item.s3path
    }));

    return transformedData;
}

async function fetchMeshOBJPresignedURL(s3path) {
    const response = await fetch(
        `${process.env.REACT_APP_API_URL}/private/data/mesh?s3path=${encodeURIComponent(s3path)}`,
        {
            method: 'GET',
            credentials: 'include',
        }
    );
    const responseBody = await response.json();
    console.log("meshPresignedURLs", responseBody);

    // return responseBody;

    for (let url of responseBody.presignedURLs) {
        // save if ends with .obj
        if (url.includes("scaled_and_centered.obj")) {
            console.log("saving obj url", url);
            return url;
        }
    }

    throw new Error("No .obj file found");
}

export { fetchImageMetaData, fetchMeshMetaData, fetchMeshOBJPresignedURL }



