import { useState, useEffect } from 'react';
import { Center, Box, Spinner } from '@chakra-ui/react'
import FolderAccordion from '../components/FolderAccordion';
import AppNavigation from '../components/AppNavigation';
import Layout from '../components/Layout';
import { fetchImageMetaData, fetchMeshMetaData } from '../utils/fetchData';


function ScanView({ navData }) {

  const [imageMetaData, setImageMetaData] = useState(null);
  const [meshMetaData, setMeshMetaData] = useState(null);

  useEffect(() => {
    setTimeout(() => { // fake delay to check spinner
      fetchImageMetaData()
        .then(imageData => setImageMetaData(imageData))
      fetchMeshMetaData()
        .then(meshData => setMeshMetaData(meshData))
    }, 1000);
  }, []);


  let mainData = null
  if (imageMetaData && meshMetaData) {
    mainData = [
      { foldername: "images", data: imageMetaData },
      { foldername: "meshes", data: meshMetaData },
    ]
  }


  return <Layout
    mainComponent={FolderAccordion}
    mainData={mainData}
    navComponent={AppNavigation}
    navData={{ navData: navData }}
  />

}


export default ScanView;