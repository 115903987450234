import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate

import { Box, Button, Text } from '@chakra-ui/react';
import AppNavigation from '../components/AppNavigation';
import AppHeader from '../components/AppHeader';
import Layout from '../components/Layout';
import LoginBlur from '../components/LoginBlur';
import "../css/landing.css"

function Landing({ navData }) {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const navigate = useNavigate();


  return (
    <div>
      <div className="video-container">
        <video autoPlay muted loop>
          <source src="InjectionMolding.mp4" type="video/mp4" />
          {/* Add additional video sources for different formats if needed */}
        </video>
      </div>
      {isLoggedIn ? (
        navigate('/scan_view') // Redirect to /scan_view if isLoggedIn is true
      ) : (
        <LoginBlur setIsLoggedIn={setIsLoggedIn} />
      )}
    </div>
  )
}


export default Landing;
