import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader'
import { useLoader } from '@react-three/fiber'
import { Outlines } from '@react-three/drei'
import { forwardRef, useImperativeHandle } from 'react';
import { MeshStandardMaterial } from 'three';

export const sampleImages1 = [
  { filename: "image1_a.jpeg", type: "image" },
  { filename: "image2_a.jpeg", type: "image" },
  { filename: "image3_a.jpeg", type: "image" },
  { filename: "image4_a.jpeg", type: "image" },
  { filename: "image5_a.jpeg", type: "image" },
  { filename: "image6_a.jpeg", type: "image" },
  { filename: "image7_a.jpeg", type: "image" },
  { filename: "image8_a.jpeg", type: "image" },
  { filename: "image9_a.jpeg", type: "image" },
  { filename: "image10_a.jpeg", type: "image" },
  { filename: "image11_a.jpeg", type: "image" },
  { filename: "image12_a.jpeg", type: "image" },
  { filename: "image13_a.jpeg", type: "image" },
  { filename: "image14_a.jpeg", type: "image" },
  { filename: "image15_a.jpeg", type: "image" },
  { filename: "image16_a.jpeg", type: "image" },
  { filename: "image17_a.jpeg", type: "image" },
  { filename: "image18_a.jpeg", type: "image" }
]

export const sampleImages2 = [
  { filename: "image1_b.jpeg", type: "image" },
  { filename: "image2_b.jpeg", type: "image" },
  { filename: "image3_b.jpeg", type: "image" },
  { filename: "image4_b.jpeg", type: "image" },
  { filename: "image5_b.jpeg", type: "image" },
  { filename: "image6_b.jpeg", type: "image" },
  { filename: "image7_b.jpeg", type: "image" },
]

export const sampleMeshes = [
  { filename: "mesh1.obj", type: "mesh" },
  { filename: "mesh2.obj", type: "mesh" },
  { filename: "mesh3.obj", type: "mesh" },
  { filename: "mesh4.obj", type: "mesh" },
  { filename: "mesh5.obj", type: "mesh" }
]

export const SampleRawData = [
  { foldername: "sampleImages1", data: sampleImages1 },
  { foldername: "sampleImages2", data: sampleImages2 }
]

export const SampleProcessedData = {
  meshes: sampleMeshes
}

export const SampleData = [
  { foldername: "images", data: SampleRawData },
  { foldername: "meshes", data: sampleMeshes },
]

export const ClipMesh = forwardRef((props, ref) => {
  const obj = useLoader(OBJLoader, '/clip.obj');
  obj.children.forEach(mesh => {
    mesh.material = new MeshStandardMaterial({
      // color: "#808080",
      // metalness: 0,
      // roughness: 0.37,
      // aoMapIntensity: 1.0,
      // ambientIntensity: 0.42,
      // envMapIntensity: 2.2,
      displacementScale: 2.1,
      normalScale: 1,
      transparent: true,
      opacity: 0.5,
      depthTest: false,
      depthWrite: false,
      // flatShading: true
    })
  })

  useImperativeHandle(ref, () => obj);

  return (
    <primitive object={obj} scale={1 / 10} position={[0, 0, 0]}>
      {/* <MeshStandardMaterial
        // attach="material"
        // color={'#9d4b4b'}
        color={'#808080'} // You can change this color
        wireframe={true}
        roughness={0.1}
        metalness={1}
      // transparent={true}
      /> */}
      {/* <Outlines thickness={0.2} color={'#000000'} /> */}

    </primitive>
  );
})