import React from 'react';
import { Canvas } from '@react-three/fiber'
import { OrbitControls, TrackballControls, Outlines } from '@react-three/drei'


function MeshCanvas({ children }) {
    console.log("MeshCanvas rendering")

    return (
        <>
            <Canvas
                style={{
                    width: '100%',
                    // height: '70vh',
                    backgroundColor: 'transparent'
                }}
                camera={{ fov: 50, near: 0.1, far: 1000, position: [0, 0, 5] }}
            >
                <ambientLight intensity={0.3} />
                <directionalLight position={[10, 10, 10]} intensity={2} />
                <directionalLight position={[-10, -10, 10]} intensity={1} />
                {children}
                <TrackballControls
                    enableDamping={true}
                    dynamicDampingFactor={0.1}
                    panSpeed={0.1}
                    rotateSpeed={2}
                    zoomSpeed={1}
                    noRotate={false}  // Ensure panning is enabled
                    noPan={false}  // Ensure panning is enabled
                    staticMoving={false}
                    enabled={true} // Ensure controls are enabled
                    keys={["KeyA", "KeyS", "ShiftLeft"]}
                />
            </Canvas>
        </>


    )
}

export default MeshCanvas