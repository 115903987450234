import AppNavigation from '../components/AppNavigation';
import AppHeader from '../components/AppHeader';
import React from 'react';
import { Grid, GridItem, Text, Box } from '@chakra-ui/react'
import { Spinner, Center } from '@chakra-ui/react'

function Layout({ navComponent, mainComponent, navData, mainData }) {

  return (
    <Grid
      templateAreas={`"header header"
                      "nav main"
                      "footer footer"`}
      gridTemplateRows={'200px 1fr 200px'}
      gridTemplateColumns={['25% 75%', '25% 75%', '20% 80%', '10% 90%', '10% 90']}
      h='100vh'
      w='90vw'
      gap='0'
      color='blackAlpha.700'
      margin="auto"

    >
      <GridItem pl='2' bg='white' area={'header'} style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-end' }}>
        <AppHeader />
      </GridItem>
      <GridItem pl='2' bg='white' area={'nav'} borderTop='1px solid black'>
        {navComponent ? (
          React.createElement(navComponent, { ...navData })
        ) :
          <Text>Component not defined</Text>
        }
      </GridItem>
      <GridItem pl='2' bg='white' area={'main'} borderTop='1px solid black' borderLeft='1px solid black'>
        {mainComponent ? (
          mainData ? (
            React.createElement(mainComponent, { mainData })
          ) : (
            <Center h="100%">
              <Box>
                <Spinner size="xl" />
              </Box>
            </Center>
          )
        ) : (
          <Text>Component not defined</Text>
        )
        }
      </GridItem>
      <GridItem pl='2' bg='white' area={'footer'}>
        Footer
      </GridItem>
    </Grid>
  );
}


export default Layout;