async function uploadToS3(files, presignedURLs, setUploadProgress) {
    try {
        console.log("presignedURLs", presignedURLs)

        const numFiles = files.length;

        let uploadedFiles = 0;

        const uploadPromises = files.map(async (file, index) => {
            const result = await fetch(presignedURLs[index], {
                method: 'PUT',
                body: file,
                headers: {
                    'Content-Type': file.type,
                },
            });
            uploadedFiles += 1
            setUploadProgress(uploadedFiles / numFiles);
            return result
        });

        const response = await Promise.all(uploadPromises);
        console.log('All files uploaded successfully', response);
    } catch (error) {
        console.error('Error uploading files', error);
    }
}

async function uploadImageMetadata({ folderName, itemNames }) {
    console.log("uploadingData:", {
        folderName: folderName,
        itemNames: itemNames
    });

    const response = await fetch(
        `${process.env.REACT_APP_API_URL}/private/data/image-set/`,
        {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                folderName: folderName,
                itemNames: itemNames
            }),
            credentials: 'include',
        }
    );
    const responseBody = await response.json();
    console.log("uploadImageMetaData", responseBody);

    return responseBody.presignedURLs;
}

export { uploadToS3, uploadImageMetadata }