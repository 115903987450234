async function handleLogin({ email, password, setIsLoggedIn, setLoginError }) {
    const response = await postAuthRequest({
        url: `${process.env.REACT_APP_API_URL}/login`,
        email,
        password,
        setLoginError
    })

    if (response.status === 200) {
        setIsLoggedIn(true);
    } else if (response.status === 401 || response.status === 403) {
        const responseBody = await response.json();
        setLoginError(responseBody.message);
    } else {
        setLoginError(`login failed: ${response.url}: ${response.status}`);
    }

}

async function handleRegister({ email, password, setLoginError, setRegisterConfirmation }) {
    const response = await postAuthRequest({
        url: `${process.env.REACT_APP_API_URL}/register/`,
        email,
        password,
        setLoginError
    })

    if (response.status === 200) {
        setRegisterConfirmation("You are registered! Please log in.");
    } else {
        setLoginError(`registration failed: ${response.url}: ${response.status}`);
    }
}

async function postAuthRequest({ url, email, password, setLoginError }) {
    try {
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            credentials: 'include',
            body: JSON.stringify({ email, password })
        });
        // Check if the response status indicates an error
        if (!response.ok) {
            // Handle the HTTP error here
            const errorMessage = `HTTP Error: ${response.status} - ${response.statusText}`;
            throw new Error(errorMessage);
        }

        return response
    } catch (error) {
        setLoginError(`Network error: ${error.message}: ${url}`);
    }
}

export { handleLogin, handleRegister }