import React, {
    useEffect,
    useState
} from 'react';
import {
    useDisclosure,
    Box,
    Text,
    Modal,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    ModalFooter,
    ModalContent,
    ModalOverlay,
    Button,
    Progress,
    Alert,
    AlertIcon,
} from "@chakra-ui/react";

function UploadProgress({ uploadProgress, onUploadClick, onModalClose }) {
    const { isOpen, onOpen, onClose } = useDisclosure()

    const closeBothModals = () => {
        onClose()
        onModalClose()
    }


    return (
        <>
            <Button
                colorScheme='messenger'
                size='md'
                fontSize='18px'
                fontWeight='regular'
                borderRadius='10px'
                onClick={() => {
                    onUploadClick()
                    onOpen()
                }}
            >
                Upload
            </Button>
            <Modal isOpen={isOpen} onClose={onClose} isCentered={true}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader> Upload Images </ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        {uploadProgress > 0.99 ? (

                            <Alert
                                status='success'
                                variant='subtle'
                                flexDirection='column'
                                alignItems='center'
                                justifyContent='center'
                                textAlign='center'
                                minHeight='80px'
                            >
                                <AlertIcon />
                                <Text style={{
                                    fontSize: '12px',
                                    fontFamily: 'Space Mono',
                                    textAlign: 'center',
                                    color: 'black'  // for a lighter gray color
                                }}>
                                    Images uploaded!
                                </Text>
                            </Alert>
                        ) : (
                            <>
                                <Box height="40px">
                                    <Text style={{
                                        fontSize: '12px',
                                        fontFamily: 'Space Mono',
                                        textAlign: 'left',
                                        color: '#888'  // for a lighter gray color
                                    }}>
                                        image upload
                                    </Text>
                                    <Progress colorScheme='messenger' size='md' borderRadius="3px" value={Number(uploadProgress) * 100} />
                                </Box>
                            </>
                        )}
                    </ModalBody>

                    <ModalFooter>
                        {uploadProgress > 0.99 ? (
                            <Button
                                colorScheme='messenger'
                                size='sm'
                                onClick={closeBothModals}
                            >
                                Done
                            </Button>
                        ) : (
                            <Button
                                isLoading
                                colorScheme='gray'
                                size='sm'
                            >
                                Done
                            </Button>
                        )}
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    );
}

export default UploadProgress;