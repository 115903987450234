import { useState } from 'react';
import {
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    AccordionIcon,
    HStack,
    Icon,
    Box,
    Text,
    Button,
    useDisclosure
} from '@chakra-ui/react'
import { ImageIcon, MeshIcon, UploadIcon } from '../components/Icons'
import { UploadFolder } from '../components/UploadFolder'
import MeshProgress from './MeshProgress';
import { MeshWindow } from './MeshViewer';

function RenderAccordion(data, depth = 0) {
    const [isHovered, setIsHovered] = useState(false);

    if (data.foldername === undefined) {
        return (
            <AccordionPanel pb="5px" pt="5px">
                <HStack spacing="2">
                    <Icon as={data.type === 'image' ? ImageIcon : MeshIcon} />
                    <Text style={{
                        fontFamily: 'Space Mono',
                        textAlign: 'left',
                        paddingLeft: '10px'
                    }}
                        fontSize={{ base: "14px", sm: "16px", md: "18px", lg: "20px", xl: "24px" }}
                    >
                        {data.filename}
                    </Text>
                </HStack>
            </AccordionPanel>
        );
    }

    return (
        <AccordionItem
            key={data.foldername}
            marginLeft={[`${depth * 10}px`, `${depth * 10}px`, `${depth * 30}px`, `${depth * 50}px`]}
            border="1px solid white">
            {({ isExpanded }) => (
                <>
                    <HStack spacing="0">
                        <h2>
                            <AccordionButton
                                color="black"
                                borderRadius='50px'
                                boxShadow='none'
                                onMouseEnter={() => setIsHovered(true)}
                                onMouseLeave={() => setIsHovered(false)}
                            >
                                <HStack spacing="5">
                                    <Text
                                        isTruncated
                                        style={{
                                            fontFamily: 'Space Mono',
                                            textAlign: 'left',
                                            paddingLeft: '10px',
                                            // whiteSpace: 'nowrap',
                                            // overflow: 'hidden',
                                            // textOverflow: 'ellipsis',
                                        }}
                                        lineHeight={['25px', '25px', '30px', '30px', '30px']}
                                        // noOfLines={1}
                                        fontSize={{ base: "14px", sm: "16px", md: "18px", lg: "20px", xl: "24px" }}
                                        maxWidth={{ base: "80px", sm: "80px", md: "200px", lg: "250px", xl: "300px" }}
                                    >
                                        {data.foldername}
                                    </Text>
                                    <AccordionIcon
                                        fontSize={{ base: "14px", sm: "16px", md: "18px", lg: "32px", xl: "32px" }}
                                        paddingRight='0px' marginTop="5px"
                                    />
                                    {isHovered && depth !== 0 && (
                                        data.type === 'mesh' ? (
                                            <MeshWindow s3path={data.s3path} />
                                        ) : (
                                            <MeshProgress s3path={data.s3path} />
                                        )
                                    )}
                                </HStack>
                            </AccordionButton>
                        </h2>
                        {(depth == 0 && data.foldername == "images") ? (<UploadFolder />) : (
                            <Text style={{
                                fontFamily: 'Space Mono',
                                textAlign: 'left',
                                paddingLeft: '10px',
                                color: '#888'  // for a lighter gray color
                            }}
                                fontSize={{ base: "8px", sm: "8px", md: "10px", lg: "18px", xl: "24px" }}
                            >
                                {data.creation}
                            </Text>
                        )}
                    </HStack>
                    <AccordionPanel>
                        <Accordion allowToggle>{data.data.map(child => RenderAccordion(child, depth + 1))} </Accordion>
                    </AccordionPanel>
                </>
            )}
        </AccordionItem>
    );
}

function FolderAccordion({ mainData }) {
    return (
        <Box marginLeft={["5px", "5px", "5px", "30px"]} marginTop={["10px", "30px", "50px"]}>
            <Accordion allowToggle>{mainData.map(item => RenderAccordion(item, 0))}</Accordion>
        </Box>
    );
}

export default FolderAccordion