import {
  Text,
  Box
} from '@chakra-ui/react'
import '../css/fonts.css';

function AppNavigation({ navData }) {
  return (

    navData.map((row, index) => (
      <Box
        key={index}
        as="button"
        hoverIndicator={{ background: 'black' }}
        onClick={() => { window.location.href = row.link; }}
        pad="1px"
        bg="white"
        style={{
          cursor: 'pointer',
          width: '100%',
          borderRadius: '0px',
          border: 'none',
        }}
        height={['43px', '43px', '60px']}
        marginTop={{
          base: index === 0 ? "30px" : "0",
          md: index === 0 ? "50px" : "0",
          lg: index === 0 ? "50px" : "0"
        }}

      >
        <Text style={{
          color: "black",
          fontFamily: 'Space Mono',
          textAlign: 'left',
          paddingLeft: '5px',
          lineHeight: '10px'
        }}
          fontSize={{ base: "14px", sm: "16px", md: "18px", lg: "20px", xl: "24px" }}>
          {row.title}
        </Text>
      </Box>
    ))
  );
}


export default AppNavigation;