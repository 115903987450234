import React, { useRef, useState, useEffect } from 'react';
import { Canvas, useThree, useFrame } from '@react-three/fiber';
import { SphereGeometry, Raycaster, Vector2, MeshBasicMaterial, Mesh } from 'three';

function LineSelector({ target }) {
    const { scene, gl, camera } = useThree();
    const mouse = useRef(new Vector2());
    const [spheres, setSpheres] = useState([]);
    const movingSphere = useRef(null); // reference to the moving sphere

    useEffect(() => {
        // Create initial sphere
        const geometry = new SphereGeometry(0.025);
        const material = new MeshBasicMaterial({ color: 0x006AFF });
        movingSphere.current = new Mesh(geometry, material);
        scene.add(movingSphere.current);
    }, [scene]);

    useFrame(() => {
        if (target && target.current) {
            const raycaster = new Raycaster();
            raycaster.setFromCamera(mouse.current, camera);
            const intersects = raycaster.intersectObjects([target.current]);

            if (intersects.length > 0) {
                const intersectionPoint = intersects[0].point;
                movingSphere.current.position.copy(intersectionPoint);
                movingSphere.current.visible = true; // Show the sphere when there is an intersection
            } else {
                movingSphere.current.visible = false; // Hide the sphere when there is no intersection
            }
        } else {
            movingSphere.current.visible = false; // Hide the sphere when there is no intersection
        }
    });

    useEffect(() => {
        const handlePointerMove = (e) => {
            const canvas = gl.domElement;
            if (!canvas) return;

            // Getting the bounding box of the canvas
            const { left, top, width, height } = canvas.getBoundingClientRect();
            // Calculate the normalized device coordinates
            mouse.current.x = ((e.clientX - left) / width) * 2 - 1;
            mouse.current.y = -((e.clientY - top) / height) * 2 + 1;
        };

        const handleDoubleClick = () => {
            // Create a new static sphere and add to the scene
            const geometry = new SphereGeometry(0.05);
            const material = new MeshBasicMaterial({ color: 0x006AFF });
            const sphere = new Mesh(geometry, material);
            sphere.position.copy(movingSphere.current.position);
            scene.add(sphere);
            setSpheres(prev => [...prev, sphere]);
        };

        gl.domElement.addEventListener('pointermove', handlePointerMove);
        gl.domElement.addEventListener('dblclick', handleDoubleClick);
        return () => {
            gl.domElement.removeEventListener('pointermove', handlePointerMove);
            gl.domElement.removeEventListener('dblclick', handleDoubleClick);
        };
    }, [gl.domElement, scene, movingSphere.current]);

    return null;
}


export default LineSelector;
