import { createRoot } from 'react-dom/client'
import React, { useRef, useState, useEffect } from 'react'
import { Canvas, useFrame, useLoader } from '@react-three/fiber'
import { OrbitControls, TrackballControls, Outlines } from '@react-three/drei'
import {
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    useDisclosure,
    Button,
    Box,
    Spinner,
    Center,
    ModalFooter
} from '@chakra-ui/react'
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader'
import { MTLLoader } from "three/examples/jsm/loaders/MTLLoader";
import { Box3, MeshStandardMaterial, Vector3 } from 'three'
import { fetchMeshOBJPresignedURL } from '../utils/fetchData'
import MeshCanvas from './MeshCanvas'

function Obj({ objPresignedURL, setLoadingMesh }) {
    const obj = useLoader(OBJLoader, objPresignedURL);
    obj.children.forEach(mesh => {
        mesh.material = new MeshStandardMaterial({
            flatShading: true,
            // wireframe: true
        })
    })

    const [isReady, setIsReady] = useState(false);

    useEffect(() => {
        if (obj) {
            console.log("loaded obj");

            setIsReady(true);
            setLoadingMesh(false);

        }
    }, [obj]);

    return isReady ? (
        <primitive object={obj} scale={1} position={[0, 0, 0]}>
            <meshStandardMaterial
                // attach="material"
                // color={'#9d4b4b'}
                color={'#808080'} // You can change this color
                wireframe={false}
                roughness={0.1}
                metalness={1}
            />
            <Outlines thickness={0.2} color={'#000000'} />

        </primitive>
    ) : null;

}

function MeshWindow({ s3path }) {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [loadingMesh, setLoadingMesh] = useState(true);

    const [objPresignedURL, setObjPresignedURL] = useState(null);

    const handleOnOpen = async () => {
        onOpen()
        console.log("url loading")
        const url = await fetchMeshOBJPresignedURL(s3path);
        setObjPresignedURL(url);
        console.log("url loaded")
    }

    const handleModalClose = () => {
        setLoadingMesh(true);
        onClose();
    }

    return (
        <>
            <Button
                colorScheme='messenger'
                variant='solid'
                height='23px'
                width='45px'
                fontSize='12px'
                fontWeight='regular'
                borderRadius='9px'
                marginRight='10px'
                onClick={() => {
                    handleOnOpen()
                }}
            >
                view
            </Button>
            < Modal size="6xl" isOpen={isOpen} onClose={handleModalClose} >
                <ModalOverlay />
                <ModalContent style={{
                    maxHeight: '80vh', height: '80vh', // Adjust the blur intensity as needed
                    backgroundColor: 'rgba(255, 255, 255, 0.85)',
                    justifyContent: 'center'
                }}
                    backdropFilter="auto" backdropBlur="8px">
                    <ModalHeader
                        fontSize={{ base: "16px", sm: "18px", md: "20px", lg: "22px", xl: "24px" }}>
                        Hold Shift to Pan
                    </ModalHeader>
                    <ModalCloseButton />
                    <ModalBody style={{ display: 'flex', flexDirection: 'column' }}>
                        <MeshCanvas>

                            <Obj
                                objPresignedURL={objPresignedURL}
                                setLoadingMesh={setLoadingMesh}
                            />
                        </MeshCanvas>
                        <div style={{ alignSelf: 'flex-end' }}>
                            {loadingMesh ? (
                                <Button
                                    isLoading
                                    colorScheme='gray'
                                    size='md'
                                >
                                    Close
                                </Button>
                            ) : (
                                <Button
                                    colorScheme='messenger'
                                    size='md'
                                    onClick={onClose}
                                >
                                    Close
                                </Button>
                            )}
                        </div>
                    </ModalBody>
                </ModalContent>
            </Modal >
        </>
    )
}

export { MeshWindow }