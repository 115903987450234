import React from 'react';
import { Group } from '@visx/group';
import { ViolinPlot, BoxPlot } from '@visx/stats';
import { LinearGradient } from '@visx/gradient';
import { scaleBand, scaleLinear } from '@visx/scale';
import genStats, { Stats } from '@visx/mock-data/lib/generators/genStats';
import { getSeededRandom, getRandomNormal } from '@visx/mock-data';
import { withTooltip, Tooltip, defaultStyles as defaultTooltipStyles } from '@visx/tooltip';
import { PatternLines } from '@visx/pattern';

// const seededRandom = getSeededRandom(0.1);
// const randomNormal = getRandomNormal.source(getSeededRandom(0.789))(4, 3);
// const data = genStats(5, randomNormal, () => 10 * seededRandom());

const x = d => d.boxPlot.x;
const min = d => d.boxPlot.min;
const max = d => d.boxPlot.max;
const median = d => d.boxPlot.median;
const firstQuartile = d => d.boxPlot.firstQuartile;
const thirdQuartile = d => d.boxPlot.thirdQuartile;
const outliers = d => d.boxPlot.outliers;

export default withTooltip(
    ({
        seed,
        width,
        height,
        tooltipOpen,
        tooltipLeft,
        tooltipTop,
        tooltipData,
        showTooltip,
        hideTooltip,
    }) => {
        const seededRandom = getSeededRandom(seed);
        const randomNormal = getRandomNormal.source(getSeededRandom(0.789))(4, 3);
        const data = genStats(5, randomNormal, () => 10 * seededRandom());


        const xMax = width;
        const yMax = height - 120;

        const xScale = scaleBand({
            range: [0, xMax],
            round: true,
            domain: data.map(x),
            padding: 0.4,
        });

        const values = data.reduce((allValues, { boxPlot }) => {
            allValues.push(boxPlot.min, boxPlot.max);
            return allValues;
        }, []);
        const minYValue = Math.min(...values);
        const maxYValue = Math.max(...values);

        const yScale = scaleLinear({
            range: [yMax, 0],
            round: true,
            domain: [minYValue, maxYValue],
        });

        const boxWidth = xScale.bandwidth();
        const constrainedWidth = Math.min(40, boxWidth);

        return width < 10 ? null : (
            <div style={{ position: 'relative' }}>
                <svg width={width} height={height}>
                    <LinearGradient id="statsplot" to="white" from="white" />
                    <rect x={0} y={0} width={width} height={height} fill="url(#statsplot)" rx={14} />
                    <PatternLines
                        id="hViolinLines"
                        height={3}
                        width={3}
                        stroke="#ced4da"
                        strokeWidth={1}
                        orientation={['horizontal']}
                    />
                    <Group top={40}>
                        {data.map((d, i) => (
                            <g key={i}>
                                <ViolinPlot
                                    data={d.binData}
                                    stroke="#006AFF"
                                    left={xScale(x(d))}
                                    width={constrainedWidth}
                                    valueScale={yScale}
                                    fill="url(#hViolinLines)"
                                />
                                <BoxPlot
                                    min={min(d)}
                                    max={max(d)}
                                    left={xScale(x(d)) + 0.3 * constrainedWidth}
                                    firstQuartile={firstQuartile(d)}
                                    thirdQuartile={thirdQuartile(d)}
                                    median={median(d)}
                                    boxWidth={constrainedWidth * 0.4}
                                    fill="#006AFF"
                                    fillOpacity={0.3}
                                    stroke="#006AFF"
                                    strokeWidth={2}
                                    valueScale={yScale}
                                    outliers={outliers(d)}
                                    minProps={{
                                        onMouseOver: () => {
                                            showTooltip({
                                                tooltipTop: yScale(min(d)) ?? 0 + 40,
                                                tooltipLeft: xScale(x(d)) + constrainedWidth + 5,
                                                tooltipData: {
                                                    min: min(d),
                                                    name: x(d),
                                                },
                                            });
                                        },
                                        onMouseLeave: () => {
                                            hideTooltip();
                                        },
                                    }}
                                    maxProps={{
                                        onMouseOver: () => {
                                            showTooltip({
                                                tooltipTop: yScale(max(d)) ?? 0 + 40,
                                                tooltipLeft: xScale(x(d)) + constrainedWidth + 5,
                                                tooltipData: {
                                                    max: max(d),
                                                    name: x(d),
                                                },
                                            });
                                        },
                                        onMouseLeave: () => {
                                            hideTooltip();
                                        },
                                    }}
                                    boxProps={{
                                        onMouseOver: () => {
                                            showTooltip({
                                                tooltipTop: yScale(median(d)) ?? 0 + 40,
                                                tooltipLeft: xScale(x(d)) + constrainedWidth + 5,
                                                tooltipData: {
                                                    ...d.boxPlot,
                                                    name: x(d),
                                                },
                                            });
                                        },
                                        onMouseLeave: () => {
                                            hideTooltip();
                                        },
                                    }}
                                    medianProps={{
                                        style: {
                                            stroke: 'white',
                                        },
                                        onMouseOver: () => {
                                            showTooltip({
                                                tooltipTop: yScale(median(d)) ?? 0 + 40,
                                                tooltipLeft: xScale(x(d)) + constrainedWidth + 5,
                                                tooltipData: {
                                                    median: median(d),
                                                    name: x(d),
                                                },
                                            });
                                        },
                                        onMouseLeave: () => {
                                            hideTooltip();
                                        },
                                    }}
                                />
                            </g>
                        ))}
                    </Group>
                </svg>

                {tooltipOpen && tooltipData && (
                    <Tooltip
                        top={tooltipTop}
                        left={tooltipLeft}
                        style={{ ...defaultTooltipStyles, backgroundColor: '#283238', color: 'white' }}
                    >
                        <div>
                            <strong>{tooltipData.name}</strong>
                        </div>
                        <div style={{ marginTop: '5px', fontSize: '12px' }}>
                            {tooltipData.max && <div>max: {tooltipData.max}</div>}
                            {tooltipData.thirdQuartile && <div>third quartile: {tooltipData.thirdQuartile}</div>}
                            {tooltipData.median && <div>median: {tooltipData.median}</div>}
                            {tooltipData.firstQuartile && <div>first quartile: {tooltipData.firstQuartile}</div>}
                            {tooltipData.min && <div>min: {tooltipData.min}</div>}
                        </div>
                    </Tooltip>
                )}
            </div>
        );
    }
);
