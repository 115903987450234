import { useState } from 'react';
import { Box, Flex, Button, Input, Alert, AlertIcon } from '@chakra-ui/react'
import { handleLogin, handleRegister } from '../utils/login';

function LoginBlur({ setIsLoggedIn }) {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [loginError, setLoginError] = useState('');
    const [registerConfirmation, setRegisterConfirmation] = useState('');

    return (
        <Box
            style={{
                position: 'fixed',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                backgroundColor: 'rgba(150, 150, 150, 0.5)',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                zIndex: 1000,
            }}
            backdropFilter={'auto'}
            backdropBlur={'20px'}
        >
            <Box
                boxShadow="lg"
                backgroundColor="white"
                width="350px"
                padding="25px"
                borderRadius="0px"
                display="flex"
                flexDirection="column"
                alignItems="center"
            >
                <Input
                    placeholder="Email"
                    marginBottom="12px"
                    size="md"
                    variant="filled"
                    isRequired={true}
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                />
                <Input
                    placeholder="Password"
                    marginBottom="12px"
                    size="md"
                    variant="filled"
                    type="password"
                    isRequired={true}
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                />
                {loginError && (
                    <Alert status="error" marginBottom="12px">
                        <AlertIcon />
                        {loginError}
                    </Alert>
                )}
                {registerConfirmation && (
                    <Alert status="success" marginBottom="12px">
                        <AlertIcon />
                        {registerConfirmation}
                    </Alert>
                )}
                <Flex mt={4}>
                    <Button
                        onClick={() => handleLogin({
                            email,
                            password,
                            setIsLoggedIn,
                            setLoginError
                        })}
                        colorScheme='messenger'
                        mr={2}
                        size="md"
                        borderRadius="3"
                    >
                        login
                    </Button>
                    <Button
                        onClick={() => handleRegister({
                            email,
                            password,
                            setLoginError,
                            setRegisterConfirmation
                        })}
                        colorScheme="gray"
                        size="md"
                        borderRadius="3"
                    >
                        signup
                    </Button>
                </Flex>
            </Box>
        </Box>
    )
}

export default LoginBlur;