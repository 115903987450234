// add all necessary chakra imports needed for AnalysisTerminal
import {
    SimpleGrid,
    Heading,
    Text,
    Button,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    Slider,
    SliderTrack,
    SliderFilledTrack,
    SliderThumb,
    Box
} from '@chakra-ui/react'
import { useState, useEffect } from 'react';
import MeshCanvas from '../components/MeshCanvas';
import { ClipMesh } from '../utils/SampleData';
import LineSelector from '../components/LineSelector';
import { useRef } from 'react';
import ViolinChart from './ViolinChart';
import { genStats, getRandomNormal, getSeededRandom } from '@visx/mock-data';

const AnalysisTerminal = () => {
    const [cardWidth, setCardWidth] = useState(70); // Initial card width.
    const [chartWidth, setChartWidth] = useState(0);
    const [chartHeight, setChartHeight] = useState(0);
    const cardRef = useRef();
    const objRef = useRef();

    const [violinSeed, setViolinSeed] = useState(0.1); // New State variable


    const handleChange = (value) => {
        if (value < 20) value = 20;
        if (value > 80) value = 80;
        setCardWidth(value);
    };

    useEffect(() => {
        if (cardRef.current) {
            setChartWidth(cardRef.current.offsetWidth * 0.9);
            setChartHeight(cardRef.current.offsetHeight * 0.7);
        }
    }, [cardWidth]);

    const handleButtonClick = () => {
        setViolinSeed(Math.random());
    };

    return (
        <Box>
            <Slider
                min={0} // Minimum card width.
                max={100} // Maximum card width.
                value={cardWidth}
                onChange={handleChange}
            >
                <SliderTrack bg="white">
                    {/* <SliderFilledTrack /> */}
                </SliderTrack>
                <SliderThumb />

            </Slider>
            <SimpleGrid height='70vh' spacing={4} templateColumns={`${cardWidth}% ${100 - cardWidth}%`}>
                <Card>
                    <CardHeader>
                        <Heading size='md'>3D View</Heading>
                    </CardHeader>
                    <CardBody>
                        <MeshCanvas>
                            <ClipMesh ref={objRef} />
                            <LineSelector target={objRef} />
                        </MeshCanvas>
                    </CardBody>
                    <CardFooter>
                        <Button>I am useless</Button>
                    </CardFooter>
                </Card>
                <Card ref={cardRef} >
                    <CardHeader>
                        <Heading size='md'>Statistics</Heading>
                    </CardHeader>
                    <CardBody>
                        <ViolinChart seed={violinSeed} width={chartWidth} height={chartHeight} />
                    </CardBody>
                    <CardFooter>
                        <Button onClick={handleButtonClick}>update</Button>
                    </CardFooter>
                </Card>
            </SimpleGrid>
        </Box >
    );
}

export default AnalysisTerminal