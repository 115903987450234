import {
  BrowserRouter,
  Routes, // instead of "Switch"
  Route,
} from "react-router-dom";
import Landing from './pages/Landing';
import ScanView from './pages/ScanView';
import './App.css';
import AnalyzeView from "./pages/AnalyzeView";

function App() {
  const navData = [
    { title: 'scan', link: '/scan_view' },
    { title: 'analyze', link: '/analysis_view' }
  ];

  return (
    <BrowserRouter>
      <Routes>
        <Route exact path="/" element={<Landing navData={navData} />} />
        <Route path="/scan_view" element={<ScanView navData={navData} />} />
        <Route path="/analysis_view" element={<AnalyzeView navData={navData} />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
