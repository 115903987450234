import React, {
    useEffect,
    useState
} from 'react';
import {
    useDisclosure,
    Box,
    Text,
    Modal,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    ModalFooter,
    ModalContent,
    ModalOverlay,
    Button,
    Progress,
    Alert,
    AlertIcon,
} from "@chakra-ui/react";
import { io } from "socket.io-client";

function MeshProgress({ s3path }) {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [currentAction, setCurrentAction] = useState("Beginning Meshing Process...");
    const [downloadProgress, setDownloadProgress] = useState("");
    const [reconstructionProgress, setReconstructionProgress] = useState("");
    const [resultUploadProgress, setResultUploadProgress] = useState("");

    const [isSaveMeshButtonActive, setIsSaveMeshButtonActive] = useState(false);

    const [socket, setSocket] = useState(null);

    useEffect(() => {
        const socketIo = io(process.env.REACT_APP_COMPUTE_API_URL, { path: "/ws/socket.io/" });
        setSocket(socketIo);

        socketIo.on(`${s3path}`, (data) => {
            console.log("data: ", data);
            if (data.type === "Downloading...") {
                setDownloadProgress(data.progress);
            } else if (data.type === "Reconstructing...") {
                setReconstructionProgress(data.progress);
            } else if (data.type === "Uploading Mesh and Materials...") {
                setResultUploadProgress(data.progress);
            } else if (data.type === "Complete!") {
                setIsSaveMeshButtonActive(true);
            }
            setCurrentAction(data.type);
        });

        return () => {
            socketIo.disconnect();
        };
    }, []);

    const handleMeshButtonClick = async () => {
        try {
            if (socket) {
                socket.emit("photogrammetry/mesh", { id: s3path });
            }
        } catch (error) {
            console.log("error in meshprogress", error);
            console.error("Error stack:", error.stack);
            // Handle error here
        }
    };

    const handleModalClose = () => {
        if (socket) {
            socket.disconnect();
            console.log("disconnected from socket.io server")
        }
        onClose();  // Calling onClose to close the modal
    };


    return (
        <>
            <Button
                colorScheme='messenger'
                variant='solid'
                height='23px'
                width='45px'
                fontSize='12px'
                fontWeight='regular'
                borderRadius='9px'
                marginRight='10px'
                onClick={() => {
                    onOpen()
                    handleMeshButtonClick()
                }}
            >
                mesh
            </Button>
            <Modal isOpen={isOpen} onClose={handleModalClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>{currentAction}</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        {currentAction === "Complete!" ? (

                            <Alert
                                status='success'
                                variant='subtle'
                                flexDirection='column'
                                alignItems='center'
                                justifyContent='center'
                                textAlign='center'
                                minHeight='80px'
                            >
                                <AlertIcon />
                                <Text style={{
                                    fontSize: '16px',
                                    fontFamily: 'Space Mono',
                                    textAlign: 'center',
                                    color: 'black'  // for a lighter gray color
                                }}>
                                    Mesh reconstructed and uploaded!
                                </Text>
                            </Alert>
                        ) : (
                            <>
                                <Box height="40px">
                                    <Text style={{
                                        fontSize: '12px',
                                        fontFamily: 'Space Mono',
                                        textAlign: 'left',
                                        color: '#888'  // for a lighter gray color
                                    }}>
                                        image download
                                    </Text>
                                    <Progress colorScheme='messenger' size='md' borderRadius="3px" value={Number(downloadProgress) * 100} />
                                </Box>
                                <Box height="40px">
                                    <Text style={{
                                        fontSize: '12px',
                                        fontFamily: 'Space Mono',
                                        textAlign: 'left',
                                        color: '#888'  // for a lighter gray color
                                    }}>
                                        mesh reconstruction
                                    </Text>
                                    <Progress colorScheme='messenger' size='md' borderRadius="3px" value={Number(reconstructionProgress) * 100} />
                                </Box>
                                <Box height="40px">
                                    <Text style={{
                                        fontSize: '12px',
                                        fontFamily: 'Space Mono',
                                        textAlign: 'left',
                                        color: '#888'  // for a lighter gray color
                                    }}>
                                        mesh & metadata upload
                                    </Text>
                                    <Progress colorScheme='messenger' size='md' borderRadius="3px" value={Number(resultUploadProgress) * 100} />
                                </Box>
                            </>
                        )}
                    </ModalBody>

                    <ModalFooter>
                        {isSaveMeshButtonActive ? (
                            <Button
                                colorScheme='messenger'
                                size='sm'
                                onClick={onClose}
                            >
                                Save Mesh
                            </Button>
                        ) : (
                            <Button
                                isLoading
                                colorScheme='gray'
                                size='sm'
                            >
                                Save Mesh
                            </Button>
                        )}
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    );
}

export default MeshProgress;