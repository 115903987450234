import React, { useCallback, useState, useMemo } from 'react';
import { useDropzone } from 'react-dropzone';
import { Box, Text, UnorderedList, ListItem, Input, VStack } from '@chakra-ui/react'
import styled from 'styled-components';

const baseStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '20px',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#eeeeee',
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  color: '#bdbdbd',
  outline: 'none',
  transition: 'border .24s ease-in-out'
};

const focusedStyle = {
  borderColor: '#2196f3'
};

const acceptStyle = {
  borderColor: '#00e676'
};

const rejectStyle = {
  borderColor: '#ff1744'
};


export const FolderPicker = ({ onFilesChange, onFolderNameChange }) => {

  //   const onDrop = useCallback((acceptedFiles) => {
  //     // Handle uploaded files
  //   }, []);

  const {
    getRootProps,
    getInputProps,
    acceptedFiles,
    isFocused,
    isDragAccept,
    isDragReject
  } = useDropzone({
    maxFiles: 200,
    onDropAccepted: (files) => {
      onFilesChange(files);
    }
  });

  const files = acceptedFiles.map(file =>
    <ListItem style={{ fontSize: "16px", fontFamily: "Space Mono" }}>
      {file.path} - {Math.round(file.size / 1024)}kb
    </ListItem>
  );

  const style = useMemo(() => ({
    ...baseStyle,
    ...(isFocused ? focusedStyle : {}),
    ...(isDragAccept ? acceptStyle : {}),
    ...(isDragReject ? rejectStyle : {})
  }), [
    isFocused,
    isDragAccept,
    isDragReject
  ]);


  return (
    <section className="container">
      <div style={{ marginBottom: "20px" }}>
        <Input
          placeholder='Type in folder name...'
          onChange={(e) => onFolderNameChange(e.target.value)}
          size='md'
        />
      </div>
      <div {...getRootProps({ className: 'dropzone', style })}>
        <input {...getInputProps()} />
        <p>Drop up to 200 files or click to choose</p>
      </div>
      <aside>
        <Text marginTop="20px" style={{ fontSize: "18px", fontFamily: 'Space Mono' }}>
          Files:
        </Text>
        <UnorderedList> {files} </UnorderedList>
      </aside>
    </section>
  );
}

